<template>
  <div class="switch-div" ref="switchDiv" :style="`background-color: ${store.b2b.colors.background[0]}; border-color:${store.b2b.colors.brand[0]}; border-style:solid;border-width:1px;`">
    <div class="first-half-switch" ref="firstHalfSwitch" @click="toggleActive()">
      <div class="paragraph capitalize" :style="active ? `color:${store.b2b.colors.background[0]}` : `color:${store.b2b.colors.brand[0]}`">
        {{ options[0] }}
      </div>
    </div>
    <div
      class="second-hlaf-switch"
      ref="secondHalfSwitch"
      :style="'transform:' + (this.active ? 'translateX(2%)' : 'translateX(98%)') + `;background-color:${store.b2b.colors.brand[0]}; `"
    ></div>
    <div class="div-block-262" @click="toggleActive()">
      <div class="paragraph capitalize" :style="!active ? `color:${store.b2b.colors.background[0]}` : `color:${store.b2b.colors.brand[0]}`">
        {{ options[1] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["options", "selectedOption"],
  created() {
    if (this.options[0] == this.selectedOption) {
      this.active = true
    }
    this.$emit("selection", this.active ? this.options[0] : this.options[1])


  },
  computed: {
    store: function () {
      return this.$store.state
    }
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    toggleActive() {
      this.active = !this.active

      this.$emit("selection", this.active ? this.options[0] : this.options[1])
    },
  },
}
</script>

<style scoped>
.div-block-262 {
  cursor: pointer;
}

.first-half-switch {
  cursor: pointer;
}
.paragraph.white {
  align-items: center;
  margin-top: 0px;
  font-size: 16px;
 
}

.paragraph.red {
  color:#d11616
 
}

.paragraph.black {
  color:black
 
}

.paragraph.capitalize {
  text-transform: capitalize;
}

.switch-div {
  width: 40vw;
  max-width: 390px;
}

@media screen and (max-width: 479px) {
  .switch-div {
    width: 60vw;
  }
}

.second-hlaf-switch {
  top:5%;
  height:90%;
}
</style>
