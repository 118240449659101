const events = [
    {
        uid: "testxxx8729349",
        image:
            "https://avatars.mds.yandex.net/i?id=e67c20f98bdc512c5d3bc20c140f8fac-5719595-images-taas-consumers&n=27&h=480&w=480",
        title: "Test Event",
        event_datetime: {
            start: 1697520939,
            end: 1697520939,
        },
        datetime: {
            published: 1697520939,
            updated: 1697520939,
        },
        position: 0,
        categories: ["Festival"],
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a augue vitae neque blandit auctor. Morbi elit nisi, dapibus in interdum ut, pretium a sem. Sed eget velit eget erat pellentesque feugiat quis non felis. Sed erat urna, vehicula sed ante pretium, molestie aliquet mi. Donec fermentum luctus laoreet. Aenean euismod, mi ac fringilla sagittis, mi libero sodales ligula, at rhoncus ex dolor ac arcu. Nullam non gravida arcu, in laoreet ex.",
        redirect_link: "https://google.com",
        slug: "test-event",
        location: "Amsterdam"
    },
]

const news = [
    {
        uid: "testxxx8729349",
        image:
            "https://avatars.mds.yandex.net/i?id=e67c20f98bdc512c5d3bc20c140f8fac-5719595-images-taas-consumers&n=27&h=480&w=480",
        title: "News article",
        event_datetime: {
            start: 1697520939,
            end: 1697520939,
        },
        datetime: {
            published: 1697520939,
            updated: 1697520939,
        },
        position: 0,
        categories: ["employee"],
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a augue vitae neque blandit auctor. Morbi elit nisi, dapibus in interdum ut, pretium a sem. Sed eget velit eget erat pellentesque feugiat quis non felis. Sed erat urna, vehicula sed ante pretium, molestie aliquet mi. Donec fermentum luctus laoreet. Aenean euismod, mi ac fringilla sagittis, mi libero sodales ligula, at rhoncus ex dolor ac arcu. Nullam non gravida arcu, in laoreet ex.",
        redirect_link: "https://google.com",
        slug: "news-article",
        location: "Amsterdam"
    },
]

const categories = [
    {
        type: "news",
        name: "employee",
        uid: "testlskfjlksjdflsdkieuriojfl",
        image:
            "https://avatars.mds.yandex.net/i?id=e67c20f98bdc512c5d3bc20c140f8fac-5719595-images-taas-consumers&n=27&h=480&w=480",
        position: 0
    },
    {
        type: "news",
        name: "holiday",
        uid: "testlskfjlksjdflsdkjddfsffl",
        image:
            "https://avatars.mds.yandex.net/i?id=e67c20f98bdc512c5d3bc20c140f8fac-5719595-images-taas-consumers&n=27&h=480&w=480",
        position: 1
    },
    {
        type: "news",
        name: "festival",
        uid: "testlskfjlksjdfls38947dkjfl",
        image:
            "https://avatars.mds.yandex.net/i?id=e67c20f98bdc512c5d3bc20c140f8fac-5719595-images-taas-consumers&n=27&h=480&w=480",
        position: 2
    },
    {
        type: "events",
        name: "festival",
        uid: "testlskfjlksj10011dflsdkjfl",
        image:
            "https://avatars.mds.yandex.net/i?id=e67c20f98bdc512c5d3bc20c140f8fac-5719595-images-taas-consumers&n=27&h=480&w=480",
        position: 3
    },
]

const options = [
    'events',
    'news'
]

export default {
    events,
    news,
    categories,
    options
}
