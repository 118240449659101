<template>
  <div @click="isClicked" class="smallcatdiv" style="border-radius:8px;padding:10px;">
    <div class="small-category grey" :style="active == id ? 'background-color:#333' : ''">
      <img
        :src="image"
        loading="lazy"
        class="image-118"
        :style="active == id ? 'filter:invert(1)' : ''"
      />
    </div>
    <div class="paragraph grey middle capitalize">{{ text }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["image", "text", "id", "active"],
  data() {
    return {}
  },
  methods: {
    isClicked() {
      this.$emit("isClicked", this.id)
    },
  },
}
</script>

<style scoped>
.image-118 {
  width: 100%;
  opacity: 70%;
}

.smallcatdiv {
  align-items: center;
}

.paragraph {
  text-transform: lowercase;
}

.small-category {
  cursor: pointer;
  width: 90px;
  height: 90px;
  padding: 25%;
}

.capitalize {
  text-transform: capitalize;
}

@media screen and (max-width: 768px) {
  .small-category {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 479px) {
  .small-category {
    width: 70px;
    height: 70px;
  }
}
</style>
