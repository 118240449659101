import { createRouter, createWebHistory } from "vue-router"
import AllAnnouncements from "@/views/AllAnnouncements.vue"
import Announcement from "@/views/Announcement.vue"
import ModifyAnnouncement from "@/views/ModifyAnnouncement.vue"
import AddCategory from "@/views/AddCategory.vue"
import Login from "@/views/Login.vue"

const routes = [
  { path: "/:uid/announcement/:type/:slug", component: Announcement, name: "Announcement" },
  {
    path: "/:uid/modifyannouncement/:type/:slug",
    component: ModifyAnnouncement,
    name: "AddAnnouncement",
  },
  { path: "/:uid/addcategory/:type/:category_id", component: AddCategory, name: "AddCategory" },
  { path: "/login", component: Login, name: "Login" },
  { path: "/:uid/:type", component: AllAnnouncements, name: "AllAnnouncements" },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
