<template>
  <div class="categorie-div">
    <div class="paragraph left-align" :style="`color:${store.b2b.colors.font[0]}`" >Category</div>
    <div
      v-for="(category, index) in categories"
      :key="index"
      style="margin-bottom: 1%; margin-right: 2%; position: relative"
    >
      <div class="delete-tile-div large-img">
        <div @click="edit(category.uid)" class="change">
          <img src="/images/edit-button-white.png" loading="lazy" class="edit-img" />
        </div>
      </div>

      <FilterButton
        :text="category.name"
        :isActive="getActive(index)"
        :id="category.uid"
        @returnSelected="addToSelection"
        @returnUnselected="removeFromSelection"
      />
    </div>
    <div>
      <AddCategoryButton :type="type" />
    </div>
  </div>
</template>
<script>
import FilterButton from "@/general/components/General/FilterButton.vue"
import AddCategoryButton from "@/general/components/AddEvent/AddCategoryButton.vue"
import HoverButton from "@/general/components/Category/HoverButton.vue"

export default {
  components: {
    FilterButton,
    AddCategoryButton,
    HoverButton,
  },
  props: ["AddCategory", "type", "selectedValue"],
  data: () => {
    return {
      isSelected: [],
      isHover: false,
    }
  },
  created() {
    this.isSelected = this.selectedValue
  },
  methods: {
    getActive(index) {
      const x = this.categories[index]

      return this.isSelected.includes(x.uid) ? true : false
    },
    addToSelection(value) {
      this.isSelected = [value]
      this.$emit("returnValue", value)
    },
    removeFromSelection(value) {
      this.isSelected = []
      this.$emit("returnValue", null)
    },
    edit(uid) {
      this.$router.push("/" + this.store.b2b.uid + "/addcategory/" + this.type + "/" + uid)
    },
  },
  computed: {
    store: function() {
      return this.$store.state;
    },
    categories: function () {
      return this.$store.state.announcements.categories.filter((x) => x.type == this.type)
    },
  },
}
</script>

<style scoped>
.paragraph.grey.left-align {
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  padding-bottom: 5%;
}
.categorie-div {
  margin-bottom: 5%;
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: flex-start;
  gap:10px;
}

.delete-tile-div {
  width: auto;
  height: auto;
}

.delete-tile-div.large-img {
  top: -20%;
  left: -15%;
  right: auto;
}

.edit-img {
  width: 100%;
  height: 100%;
}

.change {
  padding: 20%;
  width: 25px;
  height: 25px;
}
</style>
