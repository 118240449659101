
 
export default {

    day(day_input: any){
        const day = Number(day_input);
        
        if (typeof(day) !== 'number') {
            return "day"
        } else if ((day < 0) || (day > 31)) {
            return "day"
        }
        return '';
    },


    month(day_input: any, month_input: any){
        const day = Number(day_input);
        const month = Number(month_input);
        
        if (typeof(month) !== 'number') {
            return "month"
        } else if ((month < 0) || (month > 12)) {
            return "month"
        } else if ((month == 2) && (day > 29)) {
            return "month"
        } 
        return '';
    },


    year(year_input: any){
        const year = Number(year_input);
        var currentYear = new Date().getFullYear();
        
        if (typeof(year) !== 'number') {
            return "year"
        } else if ((year < 1900) || ( year > currentYear)) {
            return "year"
        } else if (year > currentYear-5) {
            return "year"
        }
        return '';
    },

    url (url: string) {
        const regexp1 = new RegExp("^[0-9A-Za-z_.-]+$");
        if (!regexp1.test(url)) {
            return false;
        } else {
            return true;
        }

    },

    email(email: any) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    password(password: string | any[]) {

        var array = []
        
        if(password.length < 6){
            array.push("min");
        }
        if(!/\d/.test(String(password))) {
            array.push("digit");
        }
        if(!/[a-z]/.test(String(password))){
            array.push("lower");
        }
        if(!/[A-Z]/.test(String(password))) {
            array.push("upper");
        }
        return array
    },


     getParameterByName(parameter:string) {
        var url = new URL(window.location.href);
        return url.searchParams.get(parameter);
      }
      


}
   


   