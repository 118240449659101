import { storage } from "@/db/firebase/index.js"

export default {
    async add(uid: string, type: string, id: string, file: any) {
        let url = ""

        const storageRef = storage.ref(uid + "/" + type + "/" + id)

        await storageRef.put(file).catch((err) => {
            throw err.message
        })
        await storageRef.getDownloadURL().then((x) => {
            url = x
        })
        return url
    },
}
