// import {
//   standard_acc,
//   general,
//   premium_widgets,
//   hospitality,
//   username_addons,
//   local_widgets,
//   maps_var,
// } from "@/general/Elements/2.0/OtherElements/Widgets/widget_media.js";

const FIRST = 0;

export default {
  getPropertyName(variable) {
    return Object.getOwnPropertyNames(variable)[0];
  },

  isReserved(uid) {
    if (uid === "reserved") {
      return true;
    }
    return false;
  },

  viaProduct(url) {
    if (url.split("=").length == 2) {
      return true;
    }
    return false;
  },

  setEmptyAccount() {
    var client_data = {};
    client_data.name = "Welcome to MOZO!";
    client_data.bio =
      "This profile could be yours, <a class='text-link bold' href='/register' target='_blank'>claim your url now</a>!";
    client_data.image = "images/pf.jpg";
    client_data.noProfile = true;
    client_data.score = 0;
    return client_data;
  },

  async sortPosition(items, numberOfWidgets) {
    var x = [...Array(numberOfWidgets)].map(() => null);
    var counter = 0;
    for (var i = 0; i < items.length; i++) {
      if (items[i] !== null) {
        x[counter] = items[i];
        x[counter].position = counter;
        counter++;
      }
    }
    return x;
  },

  sort(array) {
    array.sort(function (a, b) {
      return a.id - b.id || a.name.localeCompare(b.name);
    });
  },

  getLink(widget, input, option) {
    if (widget == null || input == null || option == null) {
      return null;
    }

    var link = "";
    if (option === "link") {
      if (
        /^https?:\/\//.test(input.toLowerCase()) ||
        /^http?:\/\//.test(input.toLowerCase())
      ) {
        link = input;
      } else {
        link = "http://" + input;
      }
    } else if (option === "URI") {
      link = input;
    } else if (option === "username") {
      if (widget === "wechat") {
        link = "weixin://" + input;
      } else {
        link = "https://" + widget + ".com" + username_addons[widget] + input;
      }
    } else if (option === "number") {
      link = "tel:" + input;
    } else if (option === "mail") {
      link = "mailto:" + input;
    } else if (maps_var.includes(option)) {
      link = "https://maps.google.com/?q=" + input;
    } else {
      link = input;
    }
    return link;
  },

  objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
  },

  split_for_input(option, input) {
    if (option === "username") {
      return input.split("/").pop();
    } else if (option === "link") {
      return input;
    } else if (option === "mail") {
      return input.split(":").pop();
    } else if (option === "number") {
      return input.split(":").pop();
    } else if (maps_var.includes(option)) {
      return input.split("=").pop();
    } else {
      return input;
    }
  },

  isOS() {
    // var isIOSChrome = winNav.userAgent.match("CriOS");

    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },

  isChrome() {
    // please note,
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;

    if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      return true;
    } else {
      return false;
    }
  },

  isChromeIOS() {
    var winNav = window.navigator;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    if (isIOSChrome) {
      return true;
    }
    return false;
  },

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  },

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  },

  rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  },

  hexToRgb(hex, opacity) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? "rgba(" +
      parseInt(result[1], 16) +
      "," +
      parseInt(result[2], 16) +
      "," +
      parseInt(result[3], 16) +
      "," +
      opacity +
      ")"
      : null;
  },

  makeGradientColor(color1, color2, percent) {
    function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
    }

    var newColor = {};

    function makeChannel(a, b) {
      return a + Math.round((b - a) * (percent / 100));
    }

    function makeColorPiece(num) {
      num = Math.min(num, 255); // not more than 255
      num = Math.max(num, 0); // not less than 0
      var str = num.toString(16);
      if (str.length < 2) {
        str = "0" + str;
      }
      return str;
    }

    color1 = hexToRgb(color1);
    color2 = hexToRgb(color2);

    newColor.r = makeChannel(color1.r, color2.r);
    newColor.g = makeChannel(color1.g, color2.g);
    newColor.b = makeChannel(color1.b, color2.b);
    // newColor.cssColor = "#" +
    //                     makeColorPiece(newColor.r) +
    //                     makeColorPiece(newColor.g) +
    //                     makeColorPiece(newColor.b);
    return (
      "#" +
      makeColorPiece(newColor.r) +
      makeColorPiece(newColor.g) +
      makeColorPiece(newColor.b)
    );
  },

  emptyFieldError(emptyField, variable, autocheck) {
    if (autocheck & (emptyField == null || emptyField == "")) {
      return "Pardone! You forgot to fill in the " + variable;
    } else {
      return "";
    }
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  isSpecialType(type) {
    if (type == "banner") {
      return true;
    } else if (type == "image_widgets") {
      return true;
    }

    return false;
  },

  getSpecialType(type) {
    if (type == "banner") {
      return {
        options: [[["link"], ["body"]]],
        titles: ["URL"],
        uids: ["url"],
      };
    } else if (type == "image_widgets") {
      return {
        options: [[["link"]]],
        titles: ["URL"],
        uids: ["url"],
      };
    }
  },

  async getAccountWidgets(premium, view, itemType) {
    if (this.isSpecialType(itemType)) {
      return this.getSpecialType(itemType);
    }

    var widgets = {
      uids: general.map((x) => {
        return x["widget"];
      }),
      titles: general.map((x) => {
        return x["name"];
      }),
      options: general.map((x) => {
        return x["options"];
      }),
    };

    var account_widgets = null;

    //start note: delete premium (view will cover all functionalities)
    if (premium) {
      // end note

      if (view == "hospitality") {
        account_widgets = await this.getWidgets(hospitality);
      } else {
        account_widgets = await this.getWidgets(premium_widgets);
      }
    } else {
      account_widgets = await this.getWidgets(standard_acc);
    }
    return await this.mergeWidgets(widgets, account_widgets);
  },

  async getWidgets(input) {
    return {
      uids: input.map((x) => {
        return x["widget"];
      }),
      titles: input.map((x) => {
        return x["name"];
      }),
      options: input.map((x) => {
        return x["options"];
      }),
    };
  },

  async mergeWidgets(widgets, account_widgets) {
    return {
      uids: widgets["uids"].concat(account_widgets["uids"]),
      titles: widgets["titles"].concat(account_widgets["titles"]),
      options: widgets["options"].concat(account_widgets["options"]),
    };
  },

  async isFieldEmpty(field_array) {
    return await field_array.forEach((item) => {
      if (item.field == null) {
        return true;
      } else if (item.field == "") {
        return true;
      }

      return false;
    });
  },

  async getOptions2(item, all_widgets) {
    if (item.type == "banner") {
      return [["link"], ["body"]];
    } else {
      var index = await all_widgets["uids"].indexOf(item.item.name);
      return await all_widgets["options"][index];
    }
  },

  dictToArray(dictionary, constant_var) {
    var array = [];
    for (var field in dictionary) {
      array.push(dictionary[field][constant_var]);
    }

    return array;
  },

  async getOptions(widget, index) {
    const array = [general, standard_acc, premium_widgets, hospitality];
    var options = null;

    for (var i = 0; i < array.length; i++) {
      options = await this.searchWidgetOptions(array[i], widget, index);
      if (options != null) {
        return options;
      }
    }
  },

  async searchWidgetOptions(collection, widget, index) {
    var q = await collection.find((x) => {
      return x["widget"] == widget;
    });
    return q != null ? q["options"][index][FIRST] : null;
  },

  async getWidgetTitle(widget) {
    const array = [general, standard_acc, premium_widgets, hospitality];
    var title = null;

    for (var i = 0; i < array.length; i++) {
      title = await this.searchWidgetTitle(array[i], widget);
      if (title != null) {
        return title;
      }
    }
  },

  async searchWidgetTitle(collection, widget) {
    var q = await collection.find((x) => {
      return x["widget"] == widget;
    });
    return q != null ? q["name"] : null;
  },

  setPlaceholder(option, uid, placeholders) {
    if (option === "link") {
      return "https://" + uid + ".com/..";
    } else if (option === "adress") {
      return "Fill in your streetname ..";
    } else if (option === "city") {
      return "Fill in your city ..";
    } else if (option === "country") {
      return "Fill in your country ..";
    } else if (option === "zip code") {
      return "Fill in your zip code ..";
    } else {
      return placeholders[option];
    }
  },

  deepcopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepcopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepcopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  },

  setScrolling(overflow, position, width) {
    document.getElementsByTagName("body")[0].style["overflow"] = overflow;
    document.getElementsByTagName("body")[0].style["position"] = position;
    document.getElementsByTagName("body")[0].style["top"] = 0;
    document.getElementsByTagName("body")[0].style["width"] = width;

    if (overflow == "hidden") {
      window.scrollTo(0, 0);
    }
  },

  setTag(gtag, widget_url, page_url, widget_title, widget_id, uid) {
    gtag.query("event", "app_click", {
      widget_url: widget_url,
      page_url: page_url,
      widget_title: widget_title,
      widget_id: widget_id,
      user_uid: uid,
    });
  },

  getAllFields(db_fields, field) {
    db_fields[field.name] = field.link;
    return db_fields;
  },

  setBackground(color1, color2) {
    return (
      "background-image: repeating-radial-gradient(circle farthest-corner at 100% 0%, " +
      color1 +
      ", " +
      color2 +
      ");" +
      "background-position: 50% 30%, 0px 0px;" +
      "background-repeat: repeat, repeat;" +
      "background-attachment: fixed, scroll;"
    );
  },

  getDateToday() {
    var today = new Date();
    return today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  },

  changeUrlWithoutReload(route) {
    history.pushState({}, null, route);
  },

  async checkEmailExists(contacts, email) {
    if (await contacts.find((x) => x.email == email)) {
      return true;
    }
    return false;
  },

  removeFromArray(arr, ...theArgs) {
    return arr.filter((val) => !theArgs[0].includes(val));
  },

  async orderArray(array) {
    return await Object.keys(array)
      .sort()
      .reduce((obj, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});
  },

  isArray(o) {
    return o != null && typeof o === "object" && o.length !== undefined;
  },

  randomGenerator(numberOfCharacters) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < numberOfCharacters; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  },

  getDataInSeconds() {
    const date = new Date()
    var dateInSeconds = Math.round(date.getTime() / 1000)
    return dateInSeconds;
  },


  getTextAreaText(text, maxWords) {
    return text.split(' ').splice(0, maxWords).join(' ');
  }
};
