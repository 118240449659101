<template>
  <div @click="isClicked" class="button black" :style="`background-color:${bgColor}`">
    <h1 class="paragraph capitalize" :style="`color:${txtColor}`">{{ text }}</h1>
  </div>
</template>

<script>
export default {
  props: ["text", "input", "bgColor", 'txtColor'],
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>

<style scoped>
.button-div {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}
</style>
