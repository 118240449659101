<template>
  <div
    class="background mozo _100vh"
    :style="setBackground(store.b2b.colors.background[0], store.b2b.colors.background[1])"
  >
    <div class="page-body-center">
      <div class="form-div width-cap">
        <h1 class="heading1 bold">Welcome again</h1>
        <p class="paragraph">Login to your account</p>
        <div class="form-block w-form">
          <form>
            <input
              @keyup.enter="login"
              ref="email"
              placeholder="Email"
              type="text"
              class="text-field marge-between w-input"
              maxlength="30"
            />
            <input
              @keyup.enter="login"
              ref="password"
              placeholder="Password"
              type="password"
              class="text-field marge-between w-input"
              maxlength="30"
            />
            <div class="div-block">
              <div class="columns w-row">
                <div class="w-col w-col-6"></div>
                <div class="w-col w-col-6">
                  <div class="rightalign-div">
                    <a href="/reset-password" class="paragraph white">Forgot password?</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div>
          <Button :text="'Login'" :bgColor="store.b2b.colors.button[0]" :txtColor="store.b2b.colors.font[0]" @isClicked="login" />
        </div>

        <template v-if="error !== ''">
          <div>
            <div id="error-message" class="error-message">{{ error }}</div>
          </div>
        </template>
      </div>
    </div>

    <LoginFooter />
  </div>
</template>

<script>
import db_auth from "@/db/controller/auth"

// in elements 2.0
import LoginFooter from "@/general/components/Login/LoginFooter.vue"
import Header from "@/general/components/General/Header.vue"

import methods from "@/general/helperMethods/general"

import Button from "@/general/components/General/Button.vue"

export default {
  components: {
    LoginFooter,
    Header,
    Button,
  },
  data() {
    return {
      error: "",
    }
  },
  async created() {
    const user = await db_auth.userState().then((user) => {
      return user
    })

    if (user != null && this.store.b2b.admins.includes(user.uid)) {
      this.isConfig = true
      window.open("/i4AH0ci2IfYZ61FcCIWe2kZA3mh2/events")
    } else {
      this.isConfig = false
    }
  },

  methods: {
    async login() {
      var email = this.$refs.email.value
      var password = this.$refs.password.value
      const response = await db_auth.signIn(email, password)
      if (response == "") {
        this.isConfig = true
        window.open("/i4AH0ci2IfYZ61FcCIWe2kZA3mh2/events", "_self")
      }
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    isConfig: {
      get() {
        return this.store.environment.isConfig
      },
      set(value) {
        this.store.environment.isConfig = value
      },
    },
  },
}
</script>

<style scoped>
.page-body-center {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 70vh;
  width: 100%;
}

.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
}
</style>
