import * as t from "io-ts"

const AnnouncementTypes = t.union([t.literal("news"), t.literal("events")])

const AnnouncementCategory = t.type({
    type: AnnouncementTypes,
    name: t.string,
    uid: t.union([t.string, t.null]),
    image: t.string,
    position: t.union([t.number, t.null]),
})

const AnnouncementItem = t.type({
    uid: t.union([t.string, t.null]),
    image: t.union([t.string, t.null]),
    title: t.string,
    event_datetime: t.type({
        start: t.union([t.number, t.null]),
        end: t.union([t.number, t.null]),
    }),
    position: t.union([t.number, t.null]),
    datetime: t.type({
        published: t.union([t.number, t.null]),
        updated: t.union([t.number, t.null]),
    }),
    categories: t.array(t.string),
    description: t.string,
    redirect_link: t.union([t.string, t.null]),
    slug: t.union([t.string, t.null]),
    location: t.string,
})

export { AnnouncementTypes, AnnouncementCategory, AnnouncementItem }
