import { IAnnouncementCollection } from "@/general/interfaces/Announcements/AnnouncementCollection"
import ArrayRepository from "@/general/objects/General/ArrayRepository"
import { isValidTypeData } from "@/general/objects/General/CheckMethod"
import store from "@/store/index"

import methods from "@/general/helperMethods/general"

import {
    AnnouncementTypes,
    AnnouncementItem,
} from "@/general/models/runtime/Announcement"
import * as t from "io-ts"

import db from "@/db/controller/announcement"

import addImage from "@/db/controller/addImage"

export default class AnnouncementCollection implements IAnnouncementCollection {
    async getAll(
        type: AnnouncementTypes,
        category: string = "all"
    ): Promise<AnnouncementItem[] | null> {
        if (!(await isValidTypeData<AnnouncementTypes>(type, AnnouncementTypes))) return null

        const announcements = new ArrayRepository<AnnouncementItem>(
            store.state.announcements[type]
        )
        const allItems = await announcements.getAllItems()
        if (category == "all") {
            return allItems
        } else {
            if (allItems == null) return null
            let array: AnnouncementItem[] = []
            for (var i = 0; i < allItems.length; i++) {
                const item = allItems[i]
                if (item["categories"].includes(category)) {
                    array.push(item)
                }
            }


            return array
        }
    }

    async getAllBackup(
        type: AnnouncementTypes,
        category: string = "all"
    ): Promise<AnnouncementItem[] | null> {
        if (!(await isValidTypeData<AnnouncementTypes>(type, AnnouncementTypes))) return null

        const announcements = new ArrayRepository<AnnouncementItem>(
            store.state.announcements[type]
        )
        const allItems = await announcements.getAllItems()
        if (category == "all") {
            return allItems
        } else {
            if (allItems == null) return null
            let array: AnnouncementItem[] = []
            for (var i = 0; i < allItems.length; i++) {
                const item = allItems[i]
                if (item["categories"].includes(category)) {
                    array.push(item)
                }
            }


            return array
        }
    }

    async search(
        announcement_name: string,
        type: AnnouncementTypes
    ): Promise<AnnouncementItem[] | null> {
        if (!(await isValidTypeData<string>(announcement_name, t.string))) return null
        if (!(await isValidTypeData<AnnouncementTypes>(type, AnnouncementTypes))) return null

        const announcements = new ArrayRepository<AnnouncementItem>(
            store.state.announcements[type]
        )
        return await announcements.searchItems(announcement_name, "title")
    }

    async get(item_uid: string, type: AnnouncementTypes): Promise<AnnouncementItem | null> {
        if (!(await isValidTypeData<string>(item_uid, t.string))) return null
        if (!(await isValidTypeData<AnnouncementTypes>(type, AnnouncementTypes))) return null

        const announcements = new ArrayRepository<AnnouncementItem>(
            store.state.announcements[type]
        )

        return await announcements.getItems([item_uid], "uid")![0]
    }
    async add(
        item: AnnouncementItem,
        type: AnnouncementTypes,
        file: File
    ): Promise<AnnouncementItem | null> {

        if (Number.isNaN(item.event_datetime.start)) {
            item.event_datetime.start = null
        } else if (Number.isNaN(item.event_datetime.end)) {
            item.event_datetime.end = null

        }
        if (!(await isValidTypeData<AnnouncementItem>(item, AnnouncementItem))) return null
        if (!(await isValidTypeData<AnnouncementTypes>(type, AnnouncementTypes))) return null
        if (file == null) return null

        const announcements = new ArrayRepository<AnnouncementItem>(
            store.state.announcements[type]
        )
        const position: number = announcements.getLength()

        const uid = "a_" + methods.randomGenerator(6) + position

        const image_url = await addImage.add(store.state.b2b.uid, type, uid, file)

        let new_announcement: AnnouncementItem = {
            uid: uid,
            image: image_url,
            title: item.title,
            event_datetime: {
                start: item.event_datetime.start,
                end: item.event_datetime.end,
            },
            position: position,
            datetime: {
                published: methods.getDataInSeconds(),
                updated: null,
            },
            categories: item.categories,
            description: item.description,
            redirect_link: item.redirect_link,
            slug: (item.title
                .toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '')) + "-" + item.datetime.published,
            location: item.location,
        }

        if (await announcements.addItems([new_announcement])) {
            await this.sortData(type)
            await db.setAnnouncement(store.state.b2b.uid, new_announcement, type)
    
            return new_announcement
        } else {
            return null
        }
    }

    async modify(
        item: AnnouncementItem,
        type: AnnouncementTypes,
        file: File
    ): Promise<AnnouncementItem | null> {

        if (!(await isValidTypeData<AnnouncementItem>(item, AnnouncementItem))) return null
        if (!(await isValidTypeData<AnnouncementTypes>(type, AnnouncementTypes))) return null

        const announcements = new ArrayRepository<AnnouncementItem>(
            store.state.announcements[type]
        )
        let new_announcement = item
        new_announcement.datetime.updated = methods.getDataInSeconds()

        if (file != null && item.uid != null) {
            const image_url = await addImage.add(store.state.b2b.uid, type, item.uid, file)
            new_announcement.image = image_url
        }

        if (await announcements.updateItems([new_announcement], "uid")![0]) {
            await this.sortData(type)
            await db.updateAnnouncement(store.state.b2b.uid, new_announcement, type)
            return new_announcement
        } else {
            return null
        }
    }
    async delete(type: AnnouncementTypes, item_uid: string): Promise<boolean> {
        if (!(await isValidTypeData<AnnouncementTypes>(type, AnnouncementTypes))) return false
        if (!(await isValidTypeData<string>(item_uid, t.string))) return false

        // initial Array Repo
        const announcements = new ArrayRepository<AnnouncementItem>(
            store.state.announcements[type]
        )

        // check if item exists
        const itemExists = announcements.doesExist([item_uid], "uid")
        if (!itemExists) return false

        // remove items
        const modifiedArray = await announcements.removeItems([item_uid], "uid")
        if (modifiedArray == null) return false

        await db.removeAnnouncement(store.state.b2b.uid, type, item_uid)

        return true
    }

    async sortData(type: AnnouncementTypes) {
        const announcements: AnnouncementItem[] = store.state.announcements[type]
        return await announcements.sort((event1: AnnouncementItem, event2: AnnouncementItem) => {
            const start1: number = event1.event_datetime.start!;
            const start2: number = event2.event_datetime.start!;

            if (start1 < start2) {
                return -1;
            } else if (start1 > start2) {
                return 1;
            } else {
                return 0;
            }
        });

    }
}
