<template>
  <div @click="isClicked" v-if="isLoaded" class="large-category indiv-cat" :style="`background-color:${store.b2b.colors.background[0]};border-color:${store.b2b.colors.brand[1]};`">
    <div class="top-half-cat">
      <div
        :style="
          'background-image:linear-gradient(90deg, rgba(0, 0, 0, 0.6) , rgba(0, 0, 0, 0.2) ), url(\'' +
          image +
          '\');background-position: 50% 50%;background-size: cover;'
        "
        loading="lazy"
        class="image-132"
      >
        <div class="date-div">
          <h6 class="date-txt">{{ converted_date }}</h6>
        </div>
      </div>
    </div>
    <div class="bottom-half-cat" :style="`filter: invert(${store.b2b.invert})`">
      <div style="padding-top: 5%">
        <div class="heading-3 black-text bolt" :style="`color: ${store.b2b.colors.font[1]}!important;`">{{ title }}</div>
        <div v-if="switchOption != 'news'" class="paragraph black uppercase" :style="`color: ${store.b2b.colors.font[1]}!important;opacity:70%;`">
          {{ converted_time }}
        </div>
      </div>

      <div v-for="(category, index) in c_names" :key="index">

      <div class="paragraph black uppercase" :style="`color: ${store.b2b.colors.font[1]}!important;opacity:70%;`">
          {{ category }}
        </div>
      </div>
      <!-- <div class="column-div-split"> -->

      <!-- <div class="plus-button">+</div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import methods from "@/general/helperMethods/general"

import AnnouncementCategoryCollection from "@/general/objects/Announcements/AnnouncementCategoryCollection"

export default {
  components: {},
  data: () => {
    return {
      c_names: [],
      c_ids: [],
      isLoaded: false,
    }
  },
  async created() {
    const x = new AnnouncementCategoryCollection()

    await this.categories.forEach(async (category) => {
      const allC = await x.get(category)
      this.c_names.push(allC.name)
    })

    this.isLoaded = true
  },
  props: [
    "image",
    "title",
    "text",
    "index",
    "type",
    "slug",
    "datetime_start",
    "datetime_end",
    "location",
    "categories",
  ],
  methods: {
    isClicked() {
      if (this.$store.state.environment.isConfig) {
        this.$router.push("/" + this.store.b2b.uid + "/modifyannouncement/" + this.type + "/" + this.slug)
      } else {
        this.$router.push("/" + this.store.b2b.uid + "/announcement/" + this.type + "/" + this.slug)
      }
    },
    minutes(datetime) {
      const minutes = new Date(datetime * 1000).getMinutes()
      return minutes.toString().length == 2 ? minutes + "" : "0" + minutes
    },
    hours(datetime) {
      const hours = new Date(datetime * 1000).getHours()
      return hours.toString().length == 2 ? hours + "" : "0" + hours
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    shortText: function () {
      return methods.getTextAreaText(this.text, 6) + "..."
    },

    day: function () {
      const day = new Date(this.datetime_start * 1000).getDate()
      return day.toString().length == 2 ? day + "" : "0" + day
    },
    month: function () {
      const month = new Date(this.datetime_start * 1000).toLocaleString("default", {
        month: "long",
      })

      return month.slice(0, 3)
    },

    uid: function () {
      return this.$store.state.b2b.uid
    },

    switchOption: function () {
      return this.store.environment.switchOption
    },

    converted_date: function () {
      return this.day + " " + this.month
    },
    converted_time: function () {
      return (
        this.hours(this.datetime_start) +
        ":" +
        this.minutes(this.datetime_start) +
        " - " +
        this.hours(this.datetime_end) +
        ":" +
        this.minutes(this.datetime_end)
      )
    },
  },
}
</script>

<style scoped>
.image-132 {
  max-height: none;
  height: 100%;
  width: 100%;
  position: relative;
}

.large-category.indiv-cat {
  height: 330px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 3px -1px #000;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-top: 0px;
  border-width: 1px;
  border-color: rgba(240, 0, 0, 0.47);
  border-style: solid;
  background-color: #000;
}

.paragraph.black.uppercase {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .large-category.indiv-cat {
    height: 280px;
  }
}

@media screen and (max-width: 479px) {
  .large-category.indiv-cat {
    height: 260px;
  }
}

.column-div-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.bottom-half-cat {
  padding: 3% 5%;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
  height: 40%;
  width: 100%;
  border: none;
  box-shadow: none;
  background:none;
}

@media screen and (max-width: 768px) {
  .bottom-half-cat {
    height: 40%;
    width: 100%;
  }
}

.top-half-cat {
  border-radius: 28px 28px 0px 0px;
  height: 60%;
}

.date-div {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 30%;
  height: 30%;
}
@media screen and (max-width: 479px) {
  .date-div {
    width: 37%;
    height: 45%;
  }
}
.date-txt {
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  color: white;
}

.heading-3 {
  text-transform: initial;
}
</style>
