import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";


// Config
import config_announcement from "./config-announcement";
import config_mozoapp from "./config-mozoapp";

// Initializing firebase app
const app1 = firebase.initializeApp(config_announcement);
const app2 = firebase.initializeApp(config_mozoapp, 'second');

const db1 = app1.firestore();

const auth = app2.auth();

const storage = firebase.storage()

export { db1, storage, auth, firebase };
