<template>
  <div
    class="background mozo"
    :style="setBackground(store.b2b.colors.background[0], store.b2b.colors.background[1])"
    v-if="isLoaded"
  >
    <Header input="ChooseFestival" :back="'AllAnnouncements'" :apps="true" />
    <Subheaderdiv @returnImage="setNewValue('image', null, $event)" />
    <div class="white-background2">
      <div class="inputfield-div">
        <InputField
          :label="'Titel'"
          :value="announcement.title"
          @returnValue="setNewValue('title', null, $event)"
        />
        <div class="paragraph grey">Start</div>
        <div class="div-block-254">
          <DateInput
            :date="announcement.event_datetime.start"
            @returnValue="setNewValue('date', 'start', $event)"
          />
          <TimeInput
            :time="announcement.event_datetime.start"
            @returnValue="setNewValue('time', 'start', $event)"
          />
        </div>
        <div class="paragraph grey">End</div>
        <div class="div-block-254">
          <DateInput
            :date="announcement.event_datetime.end"
            @returnValue="setNewValue('date', 'end', $event)"
          />
          <TimeInput
            :time="announcement.event_datetime.end"
            @returnValue="setNewValue('time', 'end', $event)"
          />
        </div>
        <InputField
          :label="'Location'"
          :value="announcement.location"
          @returnValue="setNewValue('location', null, $event)"
        />
      </div>

      <Categories :type="type" @returnValue="setNewValue('category', null, $event)" />

      <LargeDescription
        :input="'Write your description here'"
        :value="announcement.description"
        @returnValue="setNewValue('description', null, $event)"
      />
      <InputField
        :label="'Ticket link'"
        :value="announcement.redirect_link"
        @returnValue="setNewValue('redirect_link', null, $event)"
      />
      <Button :text="submit_button" @isClicked="addAnnouncement" />
      <template v-if="action == 'update'">
        <div class="paragraph black underlined">Delete announcement</div>
      </template>
    </div>
  </div>
</template>
<script>
import Subheaderdiv from "@/general/components/AddEvent/Subheaderdiv.vue"
import Header from "@/general/components/General/Header.vue"
import InputField from "@/general/components/General/InputField.vue"
import DateInput from "@/general/components/AddEvent/DateInput.vue"
import TimeInput from "@/general/components/AddEvent/TimeInput.vue"
import Categories from "@/general/components/AddEvent/Categories.vue"
import LargeDescription from "@/general/components/AddEvent/LargeDescription.vue"
import Button from "@/general/components/General/Button.vue"

import methods from "@/general/helperMethods/general"

import AnnouncementCollection from "@/general/objects/Announcements/AnnouncementCollection"

import db_announcement from "@/db/controller/announcement"

export default {
  components: {
    Header,
    Subheaderdiv,
    InputField,
    DateInput,
    TimeInput,
    Categories,
    LargeDescription,
    Button,
  },
  async created() {},
  data: () => {
    return {
      submit_button: "save announcement",
      label: "Titel",
      text: "Write your description here",
      action: "create",
      type: null,
      isLoaded: false,
      file: null,
      date: {
        start: null,
        end: null,
      },
      time: {
        start: null,
        end: null,
      },
      announcement: {
        title: null,
        event_datetime: {
          start: null,
          end: null,
        },
        datetime: {
          published: Date.now(),
          updated: null,
        },
        image: null,
        position: null,
        uid: null,
        location: null,
        description: null,
        redirect_link: null,
        categories: [],
        slug: null,
      },
    }
  },
  methods: {
    setNewValue(id1, id2, value) {
      if (id1 == "image") {
        this.file = value
      } else if (id1 == "category") {
        this.announcement[id1] = [value]
      } else if (id2 == null) {
        this.announcement[id1] = value
      } else {
        this[id1][id2] = value
      }
    },
    async addAnnouncement() {
      this.announcement.event_datetime = {
        start: this.convertDateToUnix(this.date.start + " " + this.time.start),
        end: this.convertDateToUnix(this.date.end + " " + this.time.end),
      }

      const ac = new AnnouncementCollection()
      await ac.add(this.announcement, this.type, this.file)

      this.$router.push({ name: "AllAnnouncements" })
    },
    convertDateToUnix(dateString) {
      const date = new Date(dateString)
      const unixTimestamp = date.getTime() / 1000
      return unixTimestamp
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
  },
}
</script>

<style scoped>
.div-block-254 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 3%;
  column-gap: 10%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .white-background2 {
    height: auto;
  }
}

.ImageDrop {
  justify-content: center;
  align-items: center;
}
</style>
