import { db1 } from "@/db/firebase/index.js"

import methods from "@/general/helperMethods/general"

export default {
    async setAnnouncement(b2b_uid: string, announcement: AnnouncementItem, type: string) {
        const announcement_uid: string = announcement.uid!
        const newObject: any = methods.deepcopy(announcement);
        delete newObject['uid']

        await db1
            .collection("announcements")
            .doc(b2b_uid)
            .collection(type)
            .doc(announcement_uid)
            .set(newObject)
            .catch((err: any) => {
                throw err.message;
            });
    },
    async updateAnnouncement(b2b_uid: string, announcement: AnnouncementItem, type: string) {
        const announcement_uid: string = announcement.uid!
        const newObject: any = methods.deepcopy(announcement);
        delete newObject['uid']
        await db1
            .collection("announcements")
            .doc(b2b_uid)
            .collection(type)
            .doc(announcement_uid)
            .update(newObject)
            .catch((err: any) => {
                throw err.message;
            });
    },
    async removeAnnouncement(b2b_uid: string, type: string, announcement_uid: string) {

        await db1
            .collection("announcements")
            .doc(b2b_uid)
            .collection(type)
            .doc(announcement_uid)
            .delete()
            .catch((err: any) => {
                throw err.message;
            });
    },
    async getAll(b2b_uid: string, type: string) {

        if (type == 'events') {
            return await db1
                .collection("announcements")
                .doc(b2b_uid)
                .collection(type)
                .orderBy("event_datetime.start", "asc")
                .where("event_datetime.start", ">=", Date.now() / 1000)
                .get()
                .then((snapshot: any) => {

                    return snapshot.docs.map((doc: any) => {
                        var data = doc.data();
                        data["uid"] = doc.id;
                        return data;
                    });
                })
                .catch((err: any) => {
                    throw err.message;
                });
        } else if (type == 'news') {
            return await db1
                .collection("announcements")
                .doc(b2b_uid)
                .collection(type)
                .orderBy("datetime.published", "desc")
                .get()
                .then((snapshot: any) => {

                    return snapshot.docs.map((doc: any) => {
                        var data = doc.data();
                        data["uid"] = doc.id;
                        return data;
                    });
                })
                .catch((err: any) => {
                    throw err.message;
                });
        }


    },

    async getAllBackup(b2b_uid: string, type: string) {

        if (type == 'events') {
            return await db1
                .collection("announcements")
                .doc(b2b_uid)
                .collection(type)
                .orderBy("event_datetime.start", "asc")
                .where("event_datetime.start", "<", Date.now() / 1000)
                .get()
                .then((snapshot: any) => {

                    return snapshot.docs.map((doc: any) => {
                        var data = doc.data();
                        data["uid"] = doc.id;
                        return data;
                    });
                })
                .catch((err: any) => {
                    throw err.message;
                });
        }


    },

    async get(b2b_uid: string, type: string, slug: string) {
        return await db1
            .collection("announcements")
            .doc(b2b_uid)
            .collection(type)
            .where("slug", "==", slug)
            .limit(1)
            .get()
            .then(querySnapshot => {
                if (!querySnapshot.empty) {
                    let data = querySnapshot.docs[0].data()
                    data["uid"] = querySnapshot.docs[0].id
                    return data
                }
            })
            .catch((err: any) => {
                throw err.message;
            });

    },

    async getInfo(b2b_uid: string) {
        return await db1
            .collection("announcements")
            .doc(b2b_uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    data!.id = doc.id;
                    return data;
                }
            })
            .catch((err) => {
                throw err.message;
            });

    },


}
