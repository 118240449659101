<template>
  <div class="header-column-tm margin-top choose">
    <div class="div-block-256"></div>
    <div class="heading-1 black" style="font-weight: 600; white-space: nowrap">
      Add Category
    </div>
    <div style="cursor: pointer; display: flex; justify-content: flex-end">
      <img @click="isClicked" src="/images/cancel.svg" loading="lazy" class="image-129" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "slug"],
  components: {},
  data: () => {
    return {}
  },
  computed: {
    store: function() {
      return this.$store.state;
    }
  },
  methods: {
    isClicked() {
      this.$router.push("/" + this.store.b2b.uid + "/modifyannouncement/" + this.type + "/" + this.slug)
    },
  },
}
</script>

<style scoped>
.header-column-tm {
  width: 100%;
  max-width: 400px;
}
</style>
