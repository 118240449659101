export default {
    name: "",
    uid: "",
    colors: {
        background: [],
        button: ["white"],
        brand: [],
        font: ["black"]
    },
    backlink: "",
    logo: null,
    admins: [""],
    tenant_name: "",
    invert: false

}