import { db1 } from "@/db/firebase/index.js"
import methods from "@/general/helperMethods/general"

export default {
    async setCategory(b2b_uid: string, category: AnnouncementCategory) {
        const category_uid: string = category.uid!
        const newObject: any = methods.deepcopy(category)
        delete newObject["uid"]
        await db1
            .collection("categories")
            .doc(b2b_uid)
            .collection("categories")
            .doc(category_uid)
            .set(newObject)
            .catch((err: any) => {
                throw err.message
            })
    },
    async updateCategory(b2b_uid: string, category: AnnouncementCategory) {
        const category_uid: string = category.uid!
        const newObject: any = methods.deepcopy(category)
        delete newObject["uid"]
        await db1
            .collection("categories")
            .doc(b2b_uid)
            .collection("categories")
            .doc(category_uid)
            .update(newObject)
            .catch((err: any) => {
                throw err.message
            })
    },

    async deleteCategory(b2b_uid: string, category_uid: string) {

        await db1
            .collection("categories")
            .doc(b2b_uid)
            .collection("categories")
            .doc(category_uid)
            .delete()
            .catch((err: any) => {
                throw err.message
            })
    },

    async getAll(b2b_uid: string) {   
        return await db1
            .collection("categories")
            .doc(b2b_uid)
            .collection("categories")
            .get()
            .then((snapshot: any) => {
                return snapshot.docs.map((doc: any) => {
                    var data = doc.data()
                    data["uid"] = doc.id

                    return data
                })
            })
            .catch((err: any) => {
                throw err.message
            })
    },
    async get(b2b_uid: string, uid: string) {
        return await db1
            .collection("categories")
            .doc(b2b_uid)
            .collection("categories")
            .doc(uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data()!
                    data.uid = doc.id
                    return data
                }
            })
            .catch((err) => {
                throw err.message
            })
    },
}
