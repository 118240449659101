<template>
  <div class="category" :class="active ? 'is-active' : ''" @click="isClicked">
    <div class="paragraph grey">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: ["text", "isActive", "id"],
  data: () => {
    return {
      active: false,
    }
  },
  created() {
    this.active = this.isActive
  },
  methods: {
    isClicked() {
      if (!this.active) {
        this.$emit("returnSelected", this.id)
      } else {
        this.$emit("returnUnselected", this.id)
      }
      this.active = !this.active
    },
  },
}
</script>

<style scoped>
.category {
  cursor: pointer;
  padding: 10px 20px;
}
.paragraph.grey {
  justify-content: center;
  width: 100%;
  align-content: center;
  white-space: nowrap;
}

.category.is-active {
  filter: invert(0.7);
  color: white;
}
</style>
