
import { auth } from "@/db/firebase/index"

export default {
    async userState() {
        return new Promise((resolve, reject) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                unsubscribe();
                resolve(user);
            }, reject);
        });
    },
    async signIn(email: string, password: string) {
        return await auth
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                return ""
            })
            .catch((err) => {
                let errorCode = err.code

                if (errorCode === "auth/invalid-email") {
                    return "Sorry you have used the wrong username or password"
                } else if (errorCode === "auth/user-disabled") {
                    return "User account has been disabled"
                } else if (errorCode == "auth/too-many-requests") {
                    return "Sorry you have too many login attempts try it again in 10 minutes."
                } else if (errorCode === "auth/user-not-found") {
                    return "Sorry you have used the wrong username or password"
                } else if (errorCode === "auth/wrong-password") {
                    return "Sorry you have used the wrong username or password"
                } else {
                    return "Sorry you have used the wrong username or password"
                }
            })
    },
    async logout() {
        await auth
            .signOut()
            .then(async () => {

                return null;
            })
            .catch(() => {
                throw "Not succesfully logout!";
            });
    },
    async authenticateWithFirebase(token:string) {
        try {
          const userCredential = await auth.signInWithCustomToken(token);
          
          // userCredential contains information about the authenticated user
          const user = userCredential.user;
          return user;
        } catch (error) {
          console.error("Error authenticating with Firebase:", error);
        }
      }
      
     
}