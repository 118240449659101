<template>
  <div class="description-div">
    <div class="paragraph small-bottom-margin" :style="`color:${store.b2b.colors.font[0]}`">Description</div>
    <div class="description" :style="`color:${store.b2b.colors.font[1]}`" ref="editorContainer"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import the Quill CSS file

export default {
  props: ["placeholder", "value"],
  mounted() {
    this.initEditor();
  },
  computed:{
    store: function() {
      return this.$store.state;
    }
  },
  methods: {
    initEditor() {
      this.editor = new Quill(this.$refs.editorContainer, {
        theme: 'snow'
      });

      this.editor.on('text-change', () => {
        const html = this.editor.root.innerHTML;
        this.$emit("returnValue", html);
      });

      this.editor.root.innerHTML = this.value;
    },
  },
}
</script>

<style scoped>
.description-div {
  margin-bottom: 5%;
}

.paragraph.small-bottom-margin {
  margin-bottom:2%;
}

.description {
  height: 200px;
}

/* Custom styles for Quill editor */
.ql-toolbar {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}

.ql-container {
  border: 1px solid #ccc;
}

.ql-editor {
  min-height: 100px;
  padding: 10px;
}

.ql-editor p {
  margin: 0;
}
</style>
