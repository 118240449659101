<template>
  <div @click="isClicked" class="large-category indiv-cat column1">
    <img src="/images/500px-Plus_symbol.svg.png" loading="lazy" class="image-131" />
    <div class="heading3">Add {{ text }}</div>
  </div>
</template>

<script>
export default {
  props: ["text"],
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>

<style scoped>
.large-category.indiv-cat {
  border-radius: 8px;
  height: 330px;
  width: 100%;
  margin-top: 0px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .large-category.indiv-cat {
    height: 280px;
  }
}

@media screen and (max-width: 479px) {
  .large-category.indiv-cat {
    height: 230px;
  }
}

.heading3 {
  width: auto;
}
</style>
