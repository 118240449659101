<template>
  <div style="width: 100%">
    <div v-if="showSearch" class="search-div">
      <img src="/images/search.svg" loading="lazy" class="information-image opacity-50" />
      <input
        @keyup="returnInput"
        ref="field"
        type="text"
        :placeholder="placeholder"
        class="search-field"
      />
    </div>

    <div class="search-icon-div" @click="changeSearchDisplay">
      <img
        :src="showSearch ? '/images/close.png' : '/images/search.svg'"
        loading="lazy"
        class="information-image searchiconlarge"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["switchValue"],
  data: () => {
    return {
      showSearch: false,
    }
  },
  computed: {
    placeholder: function () {
      return "Search for " + this.switchValue + "..."
    },
  },
  methods: {
    returnInput() {
      this.$emit("return", this.$refs.field.value)
    },
    changeSearchDisplay() {
      this.showSearch = !this.showSearch
    },
  },
}
</script>

<style scoped>
input {
  background-color: transparent;
  border-width: 0px;
  outline: none;
}

.search-div {
  margin-bottom: 0px;
  margin-top: 5%;
}

.searchiconlarge {
  width: 25px;
  height: 25px;
}

.search-icon-div {
  position: absolute;
  right: 10%;
  top: 1.5vh;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .search-icon-div {
    right: 5%;
    top: 1vh;
  }
}

@media screen and (max-width: 479px) {
  .search-icon-div {
    right: 5%;
    top: 0.5vh;
  }
}
</style>
