<template>
  <div
    class="ImageDrop"
    @drop="handleDrop"
    @dragover.prevent
    @dragenter.prevent
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="openFileExplorer"
    :style="`${getBackground()};width:100%;height:auto;`"
  >
    <input type="file" ref="fileInput" @change="handleFileInput" class="hidden" />
    <div v-if="!imageSrc">
      <div class="subheader-div middle margin-top">
        <img
          src="/images/icons8-compact-camera-50.png"
          loading="lazy"
          alt=""
          class="camera"
        />
        <div v-if="!hasImage && !hover" class="paragraph">{{ placeholder }}</div>
        <div v-else-if="!hasImage && hover" class="paragraph">
          Click or Drop image here
        </div>
        <div v-else class="paragraph">Upload new photo</div>
      </div>
    </div>
    <div v-if="hasImage" class="upload-new-photo">Upload new photo</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageSrc: null,
      hover: false,
      hasImage: false,
      placeholder: "Upload photo",
    }
  },
  props: ["currentImage"],
  created() {
    this.imageSrc = this.currentImage
  },
  methods: {
    handleDrop(event) {
      event.preventDefault()
      let file = event.dataTransfer.files[0]
      this.handleFile(file)
    },
    handleFileInput() {
      let file = this.$refs.fileInput.files[0]
      this.handleFile(file)
    },
    handleFile(file) {
      let reader = new FileReader()
      reader.onload = (e) => {
        let img = new Image()
        img.src = e.target.result
        img.onload = () => {
          let canvas = document.createElement("canvas")
          let ctx = canvas.getContext("2d")
          let width = 200 // set the width of the canvas
          let height = 200 // set the height of the canvas
          if (img.naturalWidth > img.naturalHeight) {
            height = (img.naturalHeight / img.naturalWidth) * width
          } else {
            width = (img.naturalWidth / img.naturalHeight) * height
          }
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0, width, height)
          this.imageSrc = canvas.toDataURL()
          this.hasImage = true
        }
      }
      reader.readAsDataURL(file)
      this.$emit("returnImage", file)
    },
    getBackground() {
      if (this.imageSrc == null) return ""
      return (
        "height:100%;width:100%;background-image:url('" +
        this.imageSrc +
        "');background-position: 50% 50%;background-size: cover; min-height:25vh;border-radius:8px 8px 0px 0px;margin-bottom:0px;"
      )
    },
    openFileExplorer() {
      this.$refs.fileInput.click()
    },
  },
}
</script>

<style scoped>
.subheader-div.middle.margin-top {
  background-image: none;
}
.ImageDrop img.full-size {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.ImageDrop {
  margin-bottom: 3%;
  border: 1px solid transparent;
  height: 27vh;
  padding: 3%;
  position: relative;
  margin-bottom: 3%;
  cursor: pointer;
  width: 100%;
  max-width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageDrop:hover {
  border: 1px solid white;
  border-radius: 8px;
  padding: 3%;
}
.hidden {
  display: none;
}
.upload-new-photo {
  align-items: center;
  font-family: "Worksans";
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.camera {
  filter: invert(100%);
}
</style>
