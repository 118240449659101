<template>
  <div class="small-categories-div " style="filter:invert(1); ">
    <div v-for="(smallcat, index) in smallcategories" :key="index">
      <Smallcat
        :image="smallcat.image"
        :text="smallcat.name"
        :id="smallcat.uid"
        :active="isActive"
        @isClicked="selectCategory"
      />
    </div>
  </div>
</template>

<script>
import Smallcat from "@/general/components/General/Smallcat.vue"

import methods from "@/general/helperMethods/general"

export default {
  components: {
    Smallcat,
  },
  props: ["type"],
  created() {},
  methods: {
    selectCategory(id) {
      if (this.isActive == id) {
        this.isActive = null
      } else {
        this.isActive = id
      }

      this.$emit("selectedCategory", this.isActive)
    },

  },
  computed: {
    smallcategories: function () {
      return this.$store.state.announcements.categories.filter((x) => x.type == this.type)
    },

  },
  data: () => {
    return {
      isActive: null,
      x: [
        {
          id: "uuid1",
          image: "/images/icon-account.svg",
          text: "all",
        },
        {
          id: "uuid2",
          image: "/images/hamburger-menu.svg",
          text: "clubbing",
        },
        {
          id: "uuid3",
          image: "/images/check_1.svg",
          text: "festival",
        },
        {
          id: "uuid4",
          image: "/images/icon-account.svg",
          text: "disco",
        },
        {
          id: "uuid5",
          image: "/images/hamburger-menu.svg",
          text: "70s",
        },
        {
          id: "uuid6",
          image: "/images/check_1.svg",
          text: "seasonal",
        },
        {
          id: "uuid7",
          image: "/images/icon-account.svg",
          text: "beer",
        },
        {
          id: "uuid8",
          image: "/images/hamburger-menu.svg",
          text: "food",
        },
      ],
    }
  },
}
</script>

<style scoped>
.small-categories-div {
  max-width: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.small-categories-div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.small-categories-div {
  padding: 0px;
  width: 100%;
  height: auto;
}
</style>
