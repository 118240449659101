<template>
  <div class="category" :style="`background-color:${store.b2b.colors.button[0]}`" @click="createCategory">
    <div class="paragraph white">+</div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  computed: {
    store: function() {
      return this.$store.state
    }
  },

  created() {},
  methods: {
    createCategory() {
      this.$router.push("/" + this.store.b2b.uid + "/addcategory/" + this.type + "/add")
    },
  },
}
</script>

<style scoped>
.category {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0px;
}

.category {
  background-color: rgb(68, 68, 68);
}

.paragraph {
  color: white;
}

.white {
  height: auto;
  margin-top: 0px;
  padding: 0px;
}
</style>
