import { IArrayRepository } from "@/general/interfaces/General/ArrayRepository";
import methods from "@/general/helperMethods/general";
import GenericObject from "@/general/objects/General/GenericObject";

export default class ArrayRepository<T> implements IArrayRepository<T> {
  private array: Array<T> = [];

  constructor(array: Array<T>) {
    this.array = array;
  }

  searchItems(name: string, property: string = "name"): T[] | null {
    // search through all items in the this.array
    // returns all items that contain name
    // property could be given to the array in order to specify which property should be searched on
    // default property should be name
    const filtered = this.array.filter(
      (obj) => (obj as any)[property] === name
    );
    return filtered.length > 0 ? filtered : null;
  }

  addItems(items: T[]): T[] | null {
    // add multiple items to the array
    if (this.array === null) return null;
    this.array.push(...items);
    return this.array;
  }

  updateItems(items: T[], id: string = "id"): T[] | null {

    if (this.array === null) return null;

    for (let i = 0; i < items.length; i++) {
      let item: any = items[i]

      let item_id: string = item[id]


      if ((this.array as any)[i][id] == item_id) {
        this.array[i] = item
      }

    }
    return items;
  }

  getItems(ids: string[], byProperty: string = "id"): T[] | null {
    // get multiple items from the array
    // in byProperty one could specify the property which should be used to searched on
    if (this.isEmpty()) return null;
    return this.array.filter((obj) => ids.includes((obj as any)[byProperty]));
  }

  getAllItems(): any[] | null {
    // get all items from the array
    return this.array;
  }

  getAllItemsById(): string[] | null {
    // get multiple ids from the array
    return this.array.map((obj) => (obj as any).id);
  }

  removeItems(ids: string[], byProperty: string | null): any[] | null {
    // remove multiple items from the array
    // in byProperty one could specify the property which should be used to searched on
    if (this.array == null) {
      return null;
    }
    if (ids == null || ids.length <= 0) {
      return null;
    }

    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < this.array.length; j++) {
        if (byProperty == null && ids[i] == (this.array as any)[j]) {
          this.array.splice(j, 1);
          j = this.array.length;
        } else if (
          byProperty != null &&
          ids[i] == (this.array as any)[j][byProperty]
        ) {
          this.array.splice(j, 1);
          j = this.array.length;
        }
      }
    }

    return this.array;
  }

  removeAllItems(): boolean {
    // remove all items from the array
    if (this.isEmpty()) return false;
    this.array = [];
    return true;
  }

  doesExist(ids: string[], property: string = "id"): boolean | null {
    // check if multiple items in the array exist
    // in property one could specify the property which should be used to searched on
    if (this.isEmpty()) return false;

    if (ids == null || ids.length <= 0) {
      return false;
    }

    const res = this.array
      .map((obj) => (obj as any)[property])
      .filter((id) => ids.includes(id));

    return res.length === ids.length;
  }

  mutateObjectAdd(
    searchValue: string,
    searchProperty: string = "id",
    addProperty: string,
    addValue: any
  ): boolean {
    const item = this.getItems([searchValue], searchProperty);
    if (item === null) return false;
    const mutableItem = item[0];
    const oldMutableItem = item[0];

    if (
      (mutableItem as any).hasOwnProperty(addProperty) &&
      (mutableItem as any)[addProperty].constructor === Array
    ) {
      // Property exists in Object 'item'.
      // This is only possible if property is an Array
      // Push new values to the existing array
      if (addValue.constructor === Array) {
        (mutableItem as any)[addProperty].push(...addValue);
      } else {
        (mutableItem as any)[addProperty].push(addValue);
      }
      return this.replaceItem(oldMutableItem, mutableItem);
    } else if (!(mutableItem as any).hasOwnProperty(addProperty)) {
      // Property does not exist, new value can be added
      (mutableItem as any)[addProperty] = addValue;
      return this.replaceItem(oldMutableItem, mutableItem);
    }

    // If we reach this, property on Object item did exist and was not an Array
    return false;
  }

  mutateObjectUpdate(
    searchValue: string,
    searchProperty: string = "id",
    updateProperty: string,
    updateValue: any,
    // THIS PARAM IS ONLY USED IN CASE OF AN ARRAY UPDATE
    nestedSearchProperty?: string
  ): boolean {
    const item = this.getItems([searchValue], searchProperty);
    if (item === null) return false;
    const mutableItem = item[0];
    const oldMutableItem = item[0];

    if ((mutableItem as any).hasOwnProperty(updateProperty)) {
      if ((mutableItem as any)[updateProperty].constructor === Array) {
        // Check if nestedSearchProperty is defined, without it, this mutation makes no sense
        if (!nestedSearchProperty) return false;
        // Property is an array, in this case, we just need to adjust one value within this array
        // Get the index of the "to be adjusted" value in this array
        const index = (mutableItem as any)[updateProperty].indexOf(
          nestedSearchProperty
        );

        // If value exists in array, adjust value
        if (~index) {
          (mutableItem as any)[updateProperty][index] = updateValue;
          return this.replaceItem(oldMutableItem, mutableItem);
        }
      }
      // Property exists in Object 'item'.
      (mutableItem as any)[updateProperty] = updateValue;
      return this.replaceItem(oldMutableItem, mutableItem);
    }

    // If we reach this, property did not exist, so could not be updated
    return false;
  }

  mutateObjectDelete(
    searchValue: string,
    searchProperty: string = "id",
    deleteProperty: string
  ): boolean {
    const item = this.getItems([searchValue], searchProperty);
    if (item === null) return false;
    const mutableItem = item[0];
    const oldMutableItem = item[0];

    try {
      if ((mutableItem as any).hasOwnProperty(deleteProperty)) {
        delete (mutableItem as any)[deleteProperty];
        return this.replaceItem(oldMutableItem, mutableItem);
      }
    } catch (e: any) {
      // Error could be TypeError or Reference Error
      return false;
    }

    return false;
  }

  getArrayOf(property: string): Partial<T>[] | null {
    // get the array back with only certain property of this.array
    return this.array.map((obj) => (obj as any)[property]);
  }

  isEmpty(): boolean {
    // check if the array is empty
    return this.array.length === 0;
  }

  getIndex(index: number): T | undefined {
    // get item of specific index place
    if (!this.isEmpty() && this.array.length >= index) return this.array[index];
  }

  getLength(): number {
    return this.array.length
  }

  private replaceItem(oldItem: any, newItem: any): boolean {
    const index = this.array.indexOf(oldItem);

    if (~index) {
      this.array[index] = newItem;
      return true;
    }

    return false;
  }
}