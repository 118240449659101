<template>
  <div
    class="background mozo"
    v-if="isLoaded"
    :style="
      setBackground(
        store.b2b.colors.background[0],
        store.b2b.colors.background[1]
      )
    "
  >
    <Header input="ChooseFestival" :back="getBackLink()" :apps="true" />
    <div
      class="festival-footer"
      :style="`background-color:${store.b2b.colors.background[0]}`"
    >
      <FestivalPicture :image="data.image" />
      <div
        class="top-festival-block"
        :style="`background-color:${store.b2b.colors.background[0]}`"
      >
        <h1
          class="heading-1 black left"
          :style="`color:${store.b2b.colors.font[0]}`"
        >
          {{ data.title }}
        </h1>

        <Dategrid
          :unix_timestamp_start="data.event_datetime.start"
          :unix_timestamp_end="data.event_datetime.end"
          :location="data.location"
        />

        <h1
          class="paragraph black"
          :style="`font-weight:700;color:${store.b2b.colors.font[1]}`"
        >
          Description
        </h1>
        <div
          class="paragraph-4"
          :style="`color:${store.b2b.colors.font[1]}`"
          v-html="transformToHTML(data.description)"
        ></div>

        <div style="width: 100%">
          <Button
            :text="text"
            :txtColor="store.b2b.colors.font[1]"
            :bgColor="store.b2b.colors.button[0]"
            @isClicked="goToTickets"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/general/components/General/Footer.vue";
import Header from "@/general/components/General/Header.vue";
import FestivalPicture from "@/general/components/Festival/FestivalPicture.vue";
import Dategrid from "@/general/components/Festival/Dategrid.vue";
import DategridPublished from "@/general/components/Festival/Dategrid.vue";
import Button from "@/general/components/General/Button.vue";

import db_announcement from "@/db/controller/announcement";

import methods from "@/general/helperMethods/general";

export default {
  components: {
    Footer,
    Header,
    FestivalPicture,
    Dategrid,
    DategridPublished,
    Button,
  },
  data: () => {
    return {
      text: "Reserve now!",
      slug: null,
      isLoaded: false,
      showEventix: false,
    };
  },
  async created() {
    this.slug = this.$route.params.slug;
    this.type = this.$route.params.type;

    this.data = await db_announcement.get(
      this.store.b2b.uid,
      this.type,
      this.slug
    );

    if (/(?:www\.|(?!www))(eventix.shop)/.test(this.data.redirect_link)) {
      this.showEventix = true;
      this.text = "Reserve now!";
    }

    this.isLoaded = true;
  },
  methods: {
    transformToHTML(text) {
      // Use a DOM parser to convert plain text to HTML
      const parser = new DOMParser();
      const html = parser.parseFromString(text, "text/html");

      // Return the inner HTML of the parsed document
      return html.documentElement.innerHTML;
    },
    isClicked() {
      this.$router.push({
        name: "Category",
      });
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2);
    },
    goToTickets() {
      if (!this.showEventix) {
        window.open(this.data.redirect_link, "_target");

        return;
      }

      const id = this.data.redirect_link.split("/").pop();
      window.open(
        "https://integrations.themozo.app/events/" +
          id +
          "/" +
          this.slug +
          "/" +
          this.store.b2b.uid,
        "_self"
      );
    },
    getBackLink() {
      return "/" + this.store.b2b.uid + "/" + this.type;
    },
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
  },
};
</script>

<style scoped>
.festival-footer {
  margin-top: 0%;
  min-height: 90vh;
  height: auto !important;
  background-color: black;
  margin-bottom: 5%;
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .festival-footer {
    height: auto !important;
  }
}

.top-festival-block {
  height: auto;
  padding-bottom: 5%;
  background: white;
}

.background.mozo {
  min-height: 100vh;
}

.paragraph-4 {
  width: auto;
}

.grid-7 {
  width: auto;
}

img {
  width: 100%;
}
</style>
