export default {
    dbSynced: false,
    isConfig: false,
    currentAnnouncement: {
        slug: null,
        uid: null
    },
    selectedAnnouncements: [],
    switchOption: null,
    gp_url: null
}