<template>
  <div class="grid-announcement" v-if="isLoaded" :style="`background-color:${store.b2b.colors.background[0]}`">
    <div v-for="(item, index) in selected_items" :key="index">
      <template v-if="isConfig == 1 && index == 0">
        <AddButton :text="switchValue" @isClicked="AddAnnouncement" />
      </template>
      <template v-else>
        <LargeCategory
          :index="index"
          :title="item.title"
          :image="item.image"
          :text="item.description"
          :type="switchValue"
          :datetime_start="item.event_datetime.start"
          :datetime_end="item.event_datetime.end"
          :slug="item.slug"
          :location="item.location"
          :categories="item.categories"
        />
      </template>
    </div>
  </div>
</template>

<script>
import LargeCategory from "./LargeCategory.vue"
import AddButton from "./AddButton.vue"

export default {
  components: {
    LargeCategory,
    AddButton,
  },
  props: ["switchValue", "refresh"],

  created() {
    try {
      this.selected_items = [
        ...this.dummyVar,
        ...this.store.announcements[this.switchValue],
      ]

      this.isLoaded = true
    } catch {}
  },

  data: () => {
    return {
      selected_items: [],

      isLoaded: false,
    }
  },
  methods: {
    AddAnnouncement() {
      this.$router.push("/" + this.store.b2b.uid + "/modifyannouncement/" + this.switchValue + "/add")
    },
  },
  watch: {
    switchValue: {
      handler() {
        this.selected_items = [
          ...this.dummyVar,
          ...this.store.announcements[this.switchValue],
        ]
      },
    },
    refresh: {
      handler() {
        this.selected_items = [
          ...this.dummyVar,
          ...this.store.environment.selectedAnnouncements,
        ]
      },
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    announcements: function () {
      return this.store.announcements
    },
    dummyVar: function () {
      return this.isConfig == 1 ? [1] : []
    },

    isConfig: function () {
      return this.store.environment.isConfig
    },
  },
}
</script>

<style scoped>
.grid-announcement {
  margin-top: 5%;
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 16px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 16px auto 16px auto;
  grid-template-rows: auto auto auto;
  background-color: black;
}

.column1 {
  grid-area: Area;
}

.column2 {
  grid-area: Area-3;
}

.margin-between-items {
  margin-top: -150px;
}

.margin-ontop-items {
  margin-top: 5%;
}

.emptyblock-item-grid {
  height: 150px;
}

@media screen and (max-width: 768px) {
  .margin-between-items {
    margin-top: -120px;
  }
  .emptyblock-item-grid {
    height: 120px;
  }
}

@media screen and (max-width: 479px) {
  .margin-between-items {
    margin-top: -100px;
  }
  .emptyblock-item-grid {
    height: 100px;
  }
}
</style>
