<template>
  <component
    class="component_class"
    :style="`width:${size};height:${size};${extra_css}`"
    :is="comp"
    v-bind="colors"
  ></component>
</template>

<script>
import { markRaw } from 'vue';


export default {
  props: {
    icon: {
      default: "calendar",
      required: true,
    },
    color1: {
      default: "#ffffff",
    },
    color2: {
      default: "#ffffff",
    },
    backgroundcolor: {
      default: "#ffffff",
    },
    size: {
      default: "25px",
    },
    extra_css: {
      default: 'margin-right:5px;'
    }
  },

  data: () => {
    return {
      colors: {
        color1: null,
        color2: null,
        background: null,
        size: null,
      },

      comp: null,
    };
  },

  async created() {
    this.setColors();
    this.comp = markRaw(await this.getComponent());
  },



  methods: {
    setColors() {
      this.colors = {
        color1: this.color1,
        color2: this.color2,
        background: this.backgroundcolor,
        size: this.size,
      };
    },

    async getComponent() {
      const componentModule = await import(`@/assets/icons/${this.icon.toLowerCase()}.vue`);
      return componentModule.default;
    },
  },
};
</script>

<style scoped>
.component_class {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  padding: 5%;
}
</style>
