<template>
  <div>
    <div
      class="background mozo"
      :style="setBackground(store.b2b.colors.background[0], store.b2b.colors.background[1])"
    >
      <Header :apps="true" @changeMessageState="changeMessageState" :showBackup="showBackup" />
      <div
        class="white-background2 choos"
        :style="setBackground(store.b2b.colors.background[0], store.b2b.colors.background[1])"
      >
        <SwitchDiv
          :options="options"
          :selectedOption="switchOption"
          @selection="getSwitchValue"
        />
        <!-- <SearchBlock :switchValue="switchOption" /> -->
        <SmallCategories :type="switchOption" @selectedCategory="filterOn" />
        <GridLargeCat :switchValue="switchOption" :refresh="refresh" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/general/components/General/Footer.vue"
import GridLargeCat from "@/general/components/Category/GridLargeCatSameHeight.vue"
import Header from "@/general/components/General/Header.vue"
import InputField from "@/general/components/General/InputField.vue"
import SearchBlock from "@/general/components/General/SearchBlock.vue"
import SmallCategories from "@/general/components/General/SmallCategories.vue"
import SwitchDiv from "@/general/components/General/Switch-div.vue"

import methods from "@/general/helperMethods/general"
import db_announcement from "@/db/controller/announcement"
import AnnouncementCollection from "@/general/objects/Announcements/AnnouncementCollection"

export default {
  components: {
    Footer,
    SwitchDiv,
    Header,
    SearchBlock,
    SmallCategories,
    InputField,
    GridLargeCat,
  },
  async created() {
    if (this.$route.params.type != null) {
      this.switchOption = this.$route.params.type
    }
  },
  data: () => {
    return {
      refresh: false,
      showBackup: true
    }
  },
  methods: {
    async changeMessageState(value) {
      this.refresh=false

      if (!value) {
        this.store.announcements.events = await db_announcement.getAll(
          this.store.b2b.uid,
          this.switchOption
        );
        
      } else {
        this.store.announcements.events = await db_announcement.getAllBackup(
         this.store.b2b.uid,
          this.switchOption
        );
      }
     this.filterOn()
    },
    getSwitchValue(value) {
      this.switchOption = value
     
      this.showBackup= value=='events'?true :false
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
    async filterOn(value) {
      if (value == null) {
        value = "all"
      }

      const ac = new AnnouncementCollection()
      this.store.environment.selectedAnnouncements = await ac.getAll(
        this.switchOption,
        value
      )

      this.refresh = !this.refresh
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },

    switchOption: {
      get() {
        return this.store.environment.switchOption
      },
      set(value) {
        this.store.environment.switchOption = value
      },
    },

    announcements: function () {
      return this.store.announcements[this.switchOption]
    },

    options: function () {
      return this.store.announcements.options
    },

    isConfig: function () {
      return this.store.environment.isConfig
    },
  },
}
</script>

<style scoped>
.white-background2.choos {
  padding-top: 2%;
  min-height: 120vh;
  position: relative;
}

.white-background2 {
  width: 100%;
}
</style>
