<template>
  <div
    class="image"
    :style="
      'background-image: url(\'' +
      image +
      '\');  background-position: 50% 50%;background-size: cover;'
    "
    loading="lazy"
    sizes="(max-width: 767px) 90vw, (max-width: 991px) 80vw, 649.9999389648438px"
    cAlass="image-121"
  ></div>
</template>

<script>
export default {
  props: ["image"],
  data: () => {
    return {};
  },
  methods: {
    isClicked() {
      this.$router.push({
        name: "ChooseFestival",
      });
    },
  },
};
</script>

<style scoped>
.image {
  border-radius: 8px;
  height: 400px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .image {
    border-radius: 8px;
    height: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .image {
    border-radius: 8px;
    height: 350px;
    width: 100%;
  }
}
</style>
