import { IAnnouncementCategory } from "@/general/interfaces/Announcements/AnnouncementCategory"
import { isValidTypeData } from "@/general/objects/General/CheckMethod"
import ArrayRepository from "@/general/objects/General/ArrayRepository"
import store from "@/store/index"

import * as t from "io-ts"
import { AnnouncementCategory } from "@/general/models/runtime/Announcement"

import methods from "@/general/helperMethods/general"

import db from "@/db/controller/category"

export default class AnnouncementCategoryCollection implements IAnnouncementCategory {
    async get(category_uid: string): Promise<AnnouncementCategory | null> {
        if (!(await isValidTypeData<string>(category_uid, t.string))) return null

        const announcements = new ArrayRepository<AnnouncementCategory>(
            store.state.announcements["categories"] as AnnouncementCategory[]
        )

        return await announcements.getItems([category_uid], "uid")![0]
    }
    async getAll(): Promise<AnnouncementCategory[] | null> {
        const announcements = new ArrayRepository<AnnouncementCategory>(
            store.state.announcements["categories"] as AnnouncementCategory[]
        )

        return await announcements.getAllItems()
    }
    async search(announcement_category: string): Promise<AnnouncementCategory[] | null> {
        if (!(await isValidTypeData<string>(announcement_category, t.string))) return null

        const announcements = new ArrayRepository<AnnouncementCategory>(
            store.state.announcements["categories"] as AnnouncementCategory[]
        )
        return await announcements.searchItems(announcement_category, "title")
    }
    async add(item: AnnouncementCategory): Promise<AnnouncementCategory | null> {
        if (!(await isValidTypeData<AnnouncementCategory>(item, AnnouncementCategory)))
            return null

        const announcements = new ArrayRepository<AnnouncementCategory>(
            store.state.announcements["categories"] as AnnouncementCategory[]
        )
        const position: number = announcements.getLength()

        var new_category: AnnouncementCategory = {
            type: item.type,
            name: item.name,
            uid: "c_" + methods.randomGenerator(6) + position,
            image: item.image,
            position: position,
        }

        if (await announcements.addItems([new_category])) {
            await db.setCategory(store.state.b2b.uid, new_category)
            return new_category
        } else {
            return null
        }
    }
    async modify(item: AnnouncementCategory): Promise<AnnouncementCategory | null> {
        if (!(await isValidTypeData<AnnouncementCategory>(item, AnnouncementCategory)))
            return null

        let new_category = item

        if (item.uid == null) return null
        new_category.position = (await this.get(item.uid))!.position


        const announcements = new ArrayRepository<AnnouncementCategory>(
            store.state.announcements["categories"] as AnnouncementCategory[]
        )



        await db.updateCategory(store.state.b2b.uid, new_category)



        return await announcements.updateItems([new_category], "uid")![0]
    }
    async delete(item_uid: string): Promise<boolean> {
        if (!(await isValidTypeData<string>(item_uid, t.string))) return false

        // initial Array Repo
        const announcements = new ArrayRepository<AnnouncementCategory>(
            store.state.announcements["categories"] as AnnouncementCategory[]
        )

        // check if item exists
        const itemExists = announcements.doesExist([item_uid], "uid")
        if (!itemExists) return false

        // remove items
        const modifiedArray = await announcements.removeItems([item_uid], "uid")
        if (modifiedArray == null) return false

        return true
    }
}
