<template>
  <div class="inputfield-div">
    <h2 class="paragraph" :style="`color:${store.b2b.colors.font[0]}`">{{ label }}</h2>
    <div class="inputfield margin-top margin-bottomi">
      <input

        :placeholder="placeholder"
        :value="value"
        @keyup="changeInput"
        class="input-text"
        ref="inputfield"
        :style="`color:${store.b2b.colors.font[0]}`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["placeholder", "label", "value"],
  methods: {
    changeInput() {
      this.$emit("returnValue", this.$refs.inputfield.value)
    },
  },
  computed:{
    store: function() {
      return this.$store.state;
    }
  }
}
</script>

<style scoped>
input {
  outline: none;
  border-width: 0px;
  width: 100%;
}
.inputfield.margin-top.margin-bottomi {
  width: 100%;
}
</style>
