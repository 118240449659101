import { createStore } from "vuex"

import announcements from "@/store/data/announcements.js"
import b2b from "@/store/data/b2b.js"
import environment from "@/store/data/environment.js"

export default createStore({
    state: {
        announcements,
        b2b,
        environment,
    },
})
