<template>
  <div v-if="isLoaded" class="w-layout-grid grid-7">
    <div class="div-block-251">
      <div class="icon-txt-div">
        <Icon :icon="'calendar'" :color1="store.b2b.colors.font[0]" :color2="store.b2b.colors.font[0]"  :size="'22px;'" />
        <div class="text-block-35" :style="`font-weight:700;color:${store.b2b.colors.font[0]}`">Date</div>
      </div>
      <div class="paragraph" :style="`color:${store.b2b.colors.font[1]}`">
        {{ date.start }}
        <span v-if="date.start != date.end">{{ line.date }} {{ date.end }}</span>
      </div>
    </div>
    <div class="div-block-251">
      <div class="icon-txt-div">
        <Icon :icon="'time'" :color1="store.b2b.colors.font[0]" :color2="store.b2b.colors.font[0]"  :size="'25px;'" />
        <div class="text-block-35" style="padding-left: 5%" :style="`font-weight:700;color:${store.b2b.colors.font[0]}`">
          Time
        </div>
      </div>
      <div class="paragraph" :style="`color:${store.b2b.colors.font[1]}`">{{ time.start }} {{ line.time }} {{ time.end }}</div>
    </div>
    <div class="div-block-251">
      <div class="icon-txt-div">
        <Icon :icon="'location'"  :color1="store.b2b.colors.font[0]" :color2="store.b2b.colors.font[0]"  :size="'25px;'" />
        <div class="text-block-35" :style="`font-weight:700;color:${store.b2b.colors.font[0]}`">Location</div>
      </div>
      <div class="paragraph" :style="`color:${store.b2b.colors.font[1]}`">{{ location }}</div>
    </div>
  </div>
</template>

<script>
// import Icon from "@/general/components/General/Icon.vue"

import Icon from '@/general/components/General/Icon.vue';


export default {
  components:{
    Icon
    // CalendarIcon
  },
  props: ["unix_timestamp_start", "unix_timestamp_end", "location"],
  data: () => {
    return {
      date: {
        start: null,
        end: null,
      },
      time: {
        start: null,
        end: null,
      },
      line: {
        date: "",
        time: "",
      },
      isLoaded:false
    }
  },
  
  created() {
    this.date.start = this.convertUnixToDate(this.unix_timestamp_start)
    this.date.end = this.convertUnixToDate(this.unix_timestamp_end)
    if (this.date.end != null) {
      this.line.date = "-"
    }

    this.time.start = this.convertUnixToTime(this.unix_timestamp_start)
    this.time.end = this.convertUnixToTime(this.unix_timestamp_end)
    if (this.time.end != null) {
      this.line.time = "-"
    }

    this.isLoaded = true
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
  },
  methods: {
    convertUnixToDate(unix_timestamp) {
      const date = new Date(unix_timestamp * 1000)
      const options = { day: "numeric", month: "long", year: "numeric" }
      const formattedDate = date.toLocaleDateString("nl-NL", options)
      return formattedDate
    },

    convertUnixToTime(unix_timestamp) {
      const date = new Date(unix_timestamp * 1000)
      const options = { hour: "numeric", minute: "numeric", hour12: false }
      const timeString = date.toLocaleTimeString("nl-NL", options)
      return timeString
    },
  },
}
</script>

<style scoped>
.text-block-35 {
  font-weight: 500;
}


.icon-txt-div {

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content:center;
  align-items: center;


}
</style>
