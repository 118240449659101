<template>
  <div v-if="isLoaded" class="inputfield small margin-top margin-bottom fullwidth"
  >
    <input
      @change="changeInput"
      ref="inputfield"
      class="dateinput"
      type="date"
      :value="date"
      :style="`color:${store.b2b.colors.font[0]}`"
    />
  </div>
</template>

<script>
export default {
  props: ["datetime"],
  data: () => {
    return {
      isLoaded: false,
    }
  },
  created() {
    this.isLoaded = true
    this.$emit("returnValue", this.date)
  },
  methods: {
    changeInput() {
      this.$emit("returnValue", this.$refs.inputfield.value)
    },
  },
  computed: {
    store: function() {
      return this.$store.state;
    },
    date: function () {
      return this.year + "-" + this.month + "-" + this.day
    },
    day: function () {
      const day = new Date(this.datetime * 1000).getDate().toString()
      return day.length == 2 ? day + "" : "0" + day
    },
    month: function () {
      const month = (new Date(this.datetime * 1000).getMonth() + 1).toString()
      return month.length == 2 ? month + "" : "0" + month
    },
    year: function () {
      return new Date(this.datetime * 1000).getFullYear()
    },
  },
}
</script>

<style scoped>
.paragraph {
  outline: none;
  border: none;
  background-color: transparent;
}

.dateinput {
  border: none;
  width: 100%;
  outline: none;
  background-color: transparent;
}

.inputfield.small.margin-top.margin-bottom.fullwidth {
  width: 100%;
}

.dateinput::-webkit-calendar-picker-indicator {
   opacity: 0; /* hide the default icon */
    cursor: pointer;
}
</style>
