<template>
  <div @click="isClicked" class="button-div wm button-image margin-bottom">
    <h1 v-if="text" class="button-text2 underline">{{ text }}</h1>
  </div>
</template>

<script>
export default {
  props: ["text", "image", "opacity"],
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>

<style scoped>
.button-div {
  cursor: pointer;
}

.button-div.button-image.margin-bottom {
  width: 100%;
}

.opacitylow {
  opacity: 80%;
  cursor: default;
}
</style>
