<template>
  <div>
    <div
      class="background mozo blur"
      :style="setBackground(store.b2b.colors.background[0], store.b2b.colors.background[1])"
    >
      <!-- start: background -->
      <AddAnnouncement />
      <!-- end: background -->
    </div>
    <AddCategory />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/general/components/General/Footer.vue"
import AddCategory from "@/general/components/AddCategory/AddCategory.vue"
import AddAnnouncement from "@/general/components/AddCategory/dummyViewModifyAnnouncement.vue"

import methods from "@/general/helperMethods/general"

export default {
  components: {
    AddCategory,
    Footer,

    AddAnnouncement,
  },
  data: () => {
    return {}
  },
  created() {},
  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
  },
}
</script>

<style scoped>
.background.mozo.blur {
  max-height: 100vh;
  overflow: hidden;
  height: 100vh;
  min-height: 0;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
.white-background2.grey {
  max-height: 60vh;
}
.white-background2 {
  min-height: 40vh;
}
</style>
