<template>
  <div class="div-block-255">
    <SmallHeader :type="type" :slug="currentAnnouncement.slug" />
    <div class="setformat">
      <div
        class="ImageDrop"
        @drop="handleDrop"
        @dragover.prevent
        @dragenter.prevent
        @mouseover="
          () => {
            hover = true
            drop_image_txt = 'Drop your image'
          }
        "
        @mouseleave="
          () => {
            hover = true
            drop_image_txt = 'Upload your image'
          }
        "
        @click="openFileExplorer"
      >
        <img ref="dropImage" :src="imageSrc" />
        <input
          type="file"
          ref="fileInput"
          @change="handleFileInput"
          class="hidden"
          accept="image/*"
        />
        <div v-if="!imageSrc">
          <div class="subheader-div middle margin-top">
            <img src="/images/126477.png" loading="lazy" alt="" class="camera" />
          </div>
        </div>
        <div class="dropText">{{ drop_image_txt }}</div>
      </div>
      <div class="inputfield-div margin-left">
        <div class="paragraph grey">Category name</div>
        <InputField :value="inputfieldValue" @returnValue="setCategoryName" />
        <Button :text="'Save'" @isClicked="addCategory" />

        <template v-if="uid != 'add'">
          <DeleteButton :text="'Delete Widget'" @isClicked="deleteWidget" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/general/components/General/Button.vue"
import InputField from "@/general/components/General/InputField.vue"
import SmallHeader from "@/general/components/AddCategory/SmallHeader.vue"

import image_db from "@/db/controller/addImage"
import category_db from "@/db/controller/category"

import DeleteButton from "@/general/components/AddCategory/ButtonNoBG.vue"

import AnnouncementCategoryCollection from "@/general/objects/Announcements/AnnouncementCategoryCollection"

export default {
  components: {
    SmallHeader,
    InputField,
    Button,
    DeleteButton,
  },
  data: () => {
    return {
      imageSrc: "",
      hover: false,
      hasImage: false,
      placeholder: "Upload photo",
      drop_image_txt: "Upload photo",
      file: null,
      category_name: null,
      type: null,
      inputfieldValue: null,
      uid: null,
    }
  },
  async created() {
    this.type = this.$route.params.type
    this.uid = this.$route.params.uid

    if (this.uid != "add") {
      const data = await category_db.get(this.store.b2b.uid, this.uid)
      this.imageSrc = data.image
      this.drop_image_txt = "Change image"
      this.inputfieldValue = data.name
      this.category_name = data.name
    }
  },
  methods: {
    handleDrop(event) {
      event.preventDefault()
      let file = event.dataTransfer.files[0]
      this.handleFile(file)
    },
    handleFileInput() {
      let file = this.$refs.fileInput.files[0]
      this.handleFile(file)
    },
    handleFile(file) {
      let reader = new FileReader()
      reader.onload = (e) => {
        let img = new Image()
        img.src = e.target.result
        img.onload = () => {
          let canvas = document.createElement("canvas")
          let ctx = canvas.getContext("2d")
          let width = 50 // set the width of the canvas
          let height = 50 // set the height of the canvas
          if (img.naturalWidth > img.naturalHeight) {
            height = (img.naturalHeight / img.naturalWidth) * width
          } else {
            width = (img.naturalWidth / img.naturalHeight) * height
          }
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0, width, height)
          this.imageSrc = canvas.toDataURL()
          this.hasImage = true
          this.file = file
        }
      }
      reader.readAsDataURL(file)
    },
    openFileExplorer() {
      this.$refs.fileInput.click()
    },
    async addCategory() {
      let action = "update"
      let url = this.imageSrc

      if (this.uid == "add") {
        this.uid = "c_" + (await this.randomGenerator(10))
        action = "create"

        url = await image_db.add(
          this.store.b2b.uid,
          "category",
          this.type + "/" + this.uid,
          this.file
        )
      }

      const new_category = {
        image: url,
        name: this.category_name,
        uid: this.uid,
        type: this.type,
        position: null,
      }

      const cat_obj = new AnnouncementCategoryCollection()

      if (action == "update") {
        await cat_obj.modify(new_category)
      } else {
        await cat_obj.add(new_category)
      }

      this.$router.push(
        "/" + this.store.b2b.uid + "/modifyannouncement/" + this.type + "/" + this.currentAnnouncement.slug
      )
    },

    async deleteWidget() {
      const cat_obj = new AnnouncementCategoryCollection()
      await cat_obj.delete(this.uid)
      await category_db.deleteCategory(this.store.b2b.uid, this.uid)

      this.$router.push(
        "/" + this.store.b2b.uid + "/modifyannouncement/" + this.type + "/" + this.currentAnnouncement.slug
      )
    },
    setCategoryName(value) {
      this.category_name = value
    },
    randomGenerator(numberOfCharacters) {
      var result = ""
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      for (var i = 0; i < numberOfCharacters; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      return result
    },
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    currentAnnouncement: function () {
      return this.store.environment.currentAnnouncement
    },
  },
}
</script>

<style scoped>
.div-block-255 {
  margin-top: 0px;
  top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.subheader-div {
  max-width: 100%;
}

.camera {
  width: 80px;
}
.hidden {
  display: none;
}
.ImageDrop {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  border: 1px solid black;
  padding: 5%;
  width: 200px;
  border-radius: 28px;
  margin-bottom: 5%;
  cursor: pointer;
}

.subheader-div.middle.margin-top {
  background-image: none;
}

.dropText {
  margin-top: 5%;
}

.upload-new-photo:hover {
  font-weight: 600;
  cursor: pointer;
}

.setformat {
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
