

import * as t from 'io-ts'




async function isValidTypeData<T>(variable: unknown, type: t.Type<T>): Promise<boolean> {
  const result = type.decode(variable);
  return result._tag == 'Right' ? true : false;

}

async function checkAllVariables(array: Array<any>, types: Array<any>) {
  for (var i = 0; i < array.length; i++) {
    let item = array[i]
    let type = types[i]
    if (!(await isValidTypeData(item, type))) {
      return false;
    }
  }

  return true;
}

export { isValidTypeData, checkAllVariables }












