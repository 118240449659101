<template>
  <div
    class="background mozo"
    
    :style="`${setBackground(store.b2b.colors.background[0], store.b2b.colors.background[1])}; `"
    v-if="isLoaded"
  >
    <Header
      input="ChooseFestival"
      :back="'/' + store.b2b.uid + '/' + type"
      :apps="true"
    />
    <div class="festival-footer" :style="`background-color:${store.b2b.colors.background[0]}`">
    <Subheaderdiv
      :currentImage="announcement.image"
      @returnImage="setNewValue('image', null, $event)"
    />
    <div class="white-background2" :style="`background-color:${store.b2b.colors.background[0]}`">
      <div class="inputfield-div">
        <InputField
          :label="'Titel'"
          :value="announcement.title"
          @returnValue="setNewValue('title', null, $event)"
        />
        <template v-if="type != 'news'">
          <div class="paragraph" :style="`color:${store.b2b.colors.font[0]}`" >Start</div>
        </template>
        <template v-else>
          <div class="paragraph" :style="`color:${store.b2b.colors.font[0]}`">Article Date</div>
        </template>
        <div class="div-block-254">
          <DateInput
            :datetime="announcement.event_datetime.start"
            @returnValue="setNewValue('date', 'start', $event)"
          />
          <TimeInput
            :datetime="announcement.event_datetime.start"
            @returnValue="setNewValue('time', 'start', $event)"
          />
        </div>
        <template v-if="type != 'news'">
          <div class="paragraph" :style="`color:${store.b2b.colors.font[0]}`">End</div>
          <div class="div-block-254">
            <DateInput
              :datetime="announcement.event_datetime.end"
              @returnValue="setNewValue('date', 'end', $event)"
            />
            <TimeInput
              :datetime="announcement.event_datetime.end"
              @returnValue="setNewValue('time', 'end', $event)"
            />
          </div>
        </template>
        <InputField
          :label="'Location'"
          :value="announcement.location"
          @returnValue="setNewValue('location', null, $event)"
        />
      </div>

      <Categories
        :type="type"
        :selectedValue="announcement.categories"
        @returnValue="setNewValue('categories', null, $event)"
      />

      <LargeDescription
        :input="'Write your description here'"
        :value="announcement.description"
        @returnValue="setNewValue('description', null, $event)"
      />
      <InputField
        :label="'Link'"
        :value="announcement.redirect_link"
        @returnValue="setNewValue('redirect_link', null, $event)"
      />
      <Button :text="submit_button" @isClicked="addAnnouncement" />
      <p v-if="error != ''" style="color: red; marge-top: 5%; marge-bottom: 5%">
        {{ this.error }}
      </p>

      <template v-if="action == 'update'">
        <div
          @click="deleteAnnouncement"
          style="cursor: pointer"
          class="paragraph black underlined"
        >
          Delete announcement
        </div>
      </template>
    </div>
  </div>
    <Footer />
  </div>
</template>
<script>
import Subheaderdiv from "@/general/components/AddEvent/Subheaderdiv.vue"
import Header from "@/general/components/General/Header.vue"
import InputField from "@/general/components/General/InputField.vue"
import DateInput from "@/general/components/AddEvent/DateInput.vue"
import TimeInput from "@/general/components/AddEvent/TimeInput.vue"
import Categories from "@/general/components/AddEvent/Categories.vue"
import LargeDescription from "@/general/components/AddEvent/LargeDescription.vue"
import Button from "@/general/components/General/Button.vue"

import methods from "@/general/helperMethods/general"

import AnnouncementCollection from "@/general/objects/Announcements/AnnouncementCollection"

import db_announcement from "@/db/controller/announcement"

import Footer from "@/general/components/General/Footer.vue"

export default {
  components: {
    Header,
    Subheaderdiv,
    InputField,
    DateInput,
    TimeInput,
    Categories,
    LargeDescription,
    Button,
    Footer,
  },
  async created() {
    this.id = this.$route.params.slug
    this.type = this.$route.params.type
    this.currentAnnouncement.slug = this.id

    if (this.id != "add") {
      this.action = "update"
      this.announcement = await db_announcement.get(
        this.store.b2b.uid,
        this.type,
        this.id
      )
      this.isLoaded = true
      this.submit_button = "edit announcement"
    } else {
      this.isLoaded = true
    }
  },
  data: () => {
    return {
      submit_button: "save announcement",
      label: "Titel",
      text: "Write your description here",
      action: "create",
      type: null,
      isLoaded: false,
      file: null,
      date: {
        start: "2023-01-01",
        end: "2023-01-01",
      },
      time: {
        start: "01:00",
        end: "01:00",
      },
      announcement: {
        title: null,
        event_datetime: {
          start: Date.now() / 1000,
          end: Date.now() / 1000,
        },
        datetime: {
          published: Date.now(),
          updated: null,
        },
        image: null,
        position: null,
        uid: null,
        location: null,
        description: null,
        redirect_link: null,
        categories: [],
        slug: null,
      },
      error: "",
    }
  },
  methods: {
    setNewValue(id1, id2, value) {
      if (id1 == "image") {
        this.file = value
      } else if (id1 == "categories") {
        if (value == null) {
          this.announcement[id1] = []
        } else {
          this.announcement[id1] = [value]
        }
      } else if (id2 == null) {
        this.announcement[id1] = value
      } else {
        this[id1][id2] = value
      }
    },
    async addAnnouncement() {
      const ac = new AnnouncementCollection()

      if (this.date.start != null && this.time.start != null) {
        this.announcement.event_datetime = {
          start: this.convertDateToUnix(this.date.start + " " + this.time.start),
          end: this.convertDateToUnix(this.date.end + " " + this.time.end),
        }
      } else {
        this.announcement.event_datetime.start =
          this.announcement.event_datetime.start * 1
        this.announcement.event_datetime.end = this.announcement.event_datetime.end * 1
      }
      let resp
      if (this.id == "add") {
        resp = await ac.add(this.announcement, this.type, this.file)
      } else {
        resp = await ac.modify(this.announcement, this.type, this.file)
      }

      if (resp == null) {
        this.error = "Sorry can't update ! You forgot some fields!"
      } else {
        this.error = ""
        this.$router.push({ path: "/" + this.store.b2b.uid + "/" + this.type })
      }
    },
    convertDateToUnix(dateString) {
      const date = new Date(dateString)
      const unixTimestamp = date.getTime() / 1000
      return unixTimestamp
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
    async deleteAnnouncement() {
      const ac = new AnnouncementCollection()
      await ac.delete(this.type, this.announcement.uid)
      this.$router.push({ path: "/" + this.store.b2b.uid + "/" + this.type })
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    currentAnnouncement: function () {
      return this.store.environment.currentAnnouncement
    },
  },
}
</script>

<style scoped>
.div-block-254 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 3%;
  column-gap: 10%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .white-background2 {
    height: auto;
  }
}

.white-background2 {
  border-radius: 0px 0px 8px 8px;
}

.ImageDrop {
  justify-content: center;
  align-items: center;
}
</style>
