<template>
  <div class="category delete-overlay"></div>
</template>

<style scoped>
.delete-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}
</style>
