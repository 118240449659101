<template>
  <div class="footer-login">
    <div class="text-div center"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footer_title: "",
    }
  },
}
</script>
