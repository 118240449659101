<template>
  <div v-if="isLoaded" class="small-input">
    <div class="paragraph black">
      <input
        @change="changeInput"
        ref="inputfield"
        class="Timeinput"
        type="time"
        :value="time"
        :style="`color:${store.b2b.colors.font[0]}`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["datetime"],
  data: () => {
    return {
      isLoaded: false,
    }
  },
  created() {
    this.isLoaded = true
    this.$emit("returnValue", this.time)
    // this.changeInput()
  },
  methods: {
    changeInput() {
      this.$emit("returnValue", this.$refs.inputfield.value)
    },
    minutes(datetime) {
      const minutes = new Date(datetime * 1000).getMinutes()
      return minutes.toString().length == 2 ? minutes + "" : "0" + minutes
    },
    hours(datetime) {
      const hours = new Date(datetime * 1000).getHours()
      return hours.toString().length == 2 ? hours + "" : "0" + hours
    },
  },
  computed: {
    
    store: function() {
      return this.$store.state;
    },
    time: function() {
      return this.hours(this.datetime) + ":" + this.minutes(this.datetime)
    }
  }
}
</script>

<style scoped>
.Timeinput {
  border: none;
  outline: none;
  background-color: transparent;
}
/* To change the color of the clock icon */
.Timeinput::-webkit-calendar-picker-indicator {
    opacity: 0; /* hide the default icon */
    cursor: pointer;
}


.small-input {
  width: 100%;
  margin-left: 0%;
}
</style>
